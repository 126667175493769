import React from 'react';
import { BrowserRouter as Router ,Routes, Route } from "react-router-dom";
import './font.css';
import Main from './Main';


function App() {
  return (
    <div className="App">
      <style>@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Rubik&display=swap');</style>
      <Router>
        <Routes>
          <Route path="/" element={<Main/>}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
