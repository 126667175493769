import React, { useLayoutEffect } from "react";
import "./Main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function Main() {
  function toSection(section) {
    const sectionContainer = document.querySelector("." + section);

    if (sectionContainer) {
      sectionContainer.scrollIntoView({ behavior: "smooth" });
    }
  }

  function unblur() {
    const blurBox = document.getElementsByClassName("blur")[0].style;

    blurBox.filter = "blur(0px)";
    blurBox.cursor = "unset";
  }

  return (
    <div className="MainWrapper">
      <div className="NavWrapper">
        <div className="NavContainer">
          <a
            className="NavTab"
            href="https://www.linkedin.com/in/kevin-hinh/"
            target="_blank"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
          <a
            className="NavTab"
            href="https://github.com/KevHnh"
            target="_blank"
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a
            className="NavTab"
            href="https://twitter.com/KevHinh"
            target="_blank"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            className="NavTab"
            href="mailto: kevinhinh19@gmail.com"
            target="_blank"
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>
      <div className="MainContainer">
        <div className="SectionContainer S1">
          <div className="sub">
            Hi, I'm Kevin. I enjoy building super simple and minimalistic yet
            creative products. My passion for design and functionality comes to
            life in every project I undertake. Whether it's crafting sleek user
            interfaces or developing elegant solutions, I thrive on the
            challenge of making complexity appear effortless. <br />
            <br />I also specialize in taking products from 0 to 100 and have
            experience doing so in a variety of different industries, from
            finance to web3. Such experiences have forced me to learn how to
            quickly master new technologies so I can deliver amazing products in
            a timely and optimal fashion. <br />
            <br />
            With that said, feel free to reach out if you have an idea you would
            like to realize.
          </div>
          <a href="mailto: kevinhinh19@gmail.com" id="btn" className="btn">
            Let's chat
          </a>
        </div>
        <div className="SectionContainer S2">
          <div className="sub">A few things I built</div>
          <div className="projectsContainer">
            <div className="projectBox">
              <div className="projectInfo">
                <a href="https://aplican.co" target="_blank" className="bold">
                  Aplican
                </a>
                <div className="desc">2023</div>
                <div className="status">
                  <div className="activeCircle"></div>
                  <div className="statusText">ACTIVE</div>
                </div>
              </div>
              <div className="projectDesc">
                <div className="desc">
                  A web application that helps users stay organized and stay
                  ahead throughout their job hunting journey.
                </div>
                <div className="desc">Job Board | Tracker | AI Tools</div>
              </div>
            </div>
            <div className="projectBox">
              <div className="projectInfo">
                <a
                  href="https://kevhinh.gumroad.com/l/mockai"
                  target="_blank"
                  className="bold"
                >
                  MockAI
                </a>
                <div className="desc">2023</div>
                <div className="status">
                  <div className="inactiveCircle"></div>
                  <div className="statusText">INACTIVE</div>
                </div>
              </div>
              <div className="projectDesc">
                <div className="desc">
                  A LeetCode inspired technical interview practice platform that
                  leverages AI to create a realistic interview experience.
                </div>
                <div className="desc">
                  Custom Personalization | Compiler | AI Interviewer
                </div>
              </div>
            </div>

            <div className="projectBox">
              <div className="projectInfo">
                <a
                  href="https://github.com/KevHnh/Gainer"
                  target="_blank"
                  className="bold"
                >
                  Gainer
                </a>
                <div className="desc">2022</div>
                <div className="status">
                  <div className="inactiveCircle"></div>
                  <div className="statusText">INACTIVE</div>
                </div>
              </div>
              <div className="projectDesc">
                <div className="desc">
                  A Node.js bot that scraped realtime stock data and identified
                  top gaining stocks that were optionable in order to make low
                  risk put calls.
                </div>
                <div className="desc">
                  Realtime Updates | Automatic Alerts | Custom Commands
                </div>
              </div>
            </div>
            <div className="projectBox">
              <div className="projectInfo">
                <a
                  href="https://ezsea.netlify.app/"
                  target="_blank"
                  className="bold"
                >
                  Ezsea
                </a>
                <div className="desc">2022</div>
                <div className="status">
                  <div className="inactiveCircle"></div>
                  <div className="statusText">INACTIVE</div>
                </div>
              </div>
              <div className="projectDesc">
                <div className="desc">
                  A web3 powered education platform that aimed to reward active
                  users in the cryptocurrency, Solana.
                </div>
                <div className="desc">
                  NFT Integration | Wallet Validation | Community Focused
                </div>
              </div>
            </div>
            <div
              className="projectBox blur"
              onClick={() => {
                unblur();
              }}
            >
              <div className="projectInfo">
                <a className="bold">Freelance Work</a>
                <div className="desc">2022-2023</div>
                <div className="status">
                  <div className="ndaCircle"></div>
                  <div className="statusText">SIGNED NDA</div>
                </div>
              </div>
              <div className="projectDesc">
                <div className="desc">
                  Variety of different tools and web apps built for clients
                  across multiple industries ranging from finance to web3.
                </div>
                <div className="desc">
                  Web Scraping | Automation | Communication
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
